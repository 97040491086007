import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { Col } from 'react-bootstrap';
import KDRow from './Row';
import React from 'react';
import { bgWhite } from '../styles/background.css';
import { portofolio } from '../styles/row.css';
import { portofolioImage } from '../styles/portofolio.css';

interface PortofolioProps {
  images: IGatsbyImageData[];
}

export default function Portofolio({ images }: PortofolioProps) {
  var Portofolio = images.map(function (image: IGatsbyImageData) {
    return (
      <Col md={3} sm={3} lg={3} xl={3} xs={12} className={portofolio}>
        <GatsbyImage
          image={image}
          alt=""
          objectFit="cover"
          className={portofolioImage}
        />
      </Col>
    );
  });
  return <KDRow className={bgWhite}>{Portofolio}</KDRow>;
}
