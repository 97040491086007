import * as React from 'react';

import { graphql, type PageProps } from 'gatsby';

import KDContainer from '../components/Container';
import KDFooter from '../components/Footer';
import { KDHead } from '../components/Head';
import KDNav from '../components/Navbar';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { backgroundPrimary } from '../styles/background.css';
import Portofolio from '../components/Portofolio';

const PortofolioPage = ({
  data,
}: PageProps<Queries.LandingPortofolioQueryQuery>) => {
  let images: IGatsbyImageData[] = new Array();
  data.allFile.edges.forEach(function (image) {
    images.push(image.node.childImageSharp.gatsbyImageData);
  });

  const rowClasses = backgroundPrimary;
  return (
    <main>
      <KDContainer>
        <KDNav />
        <Portofolio images={images} />
        <KDFooter />
      </KDContainer>
    </main>
  );
};

export default PortofolioPage;
export const LandingPortofolioQuery = graphql`
  query LandingPortofolioQuery {
    allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export const Head = () => <KDHead />;
